import type { SelectGroups, SelectItem } from '@freelancer/ui/select';
import {
  DeloitteBusinessLineApi,
  DeloitteContributionApi,
  DeloitteGigWorkerLevelApi,
  DeloitteIndustryApi,
  DeloitteIndustryGroupApi,
  DeloitteOfferingApi,
  DeloitteOfferingPortfolioApi,
  DeloittePracticeApi,
  DeloittePracticeGroupApi,
  DeloitteProjectTypeApi,
  DeloitteUtilizationApi,
} from 'api-typings/projects/deloitte';
import { countries } from '../countries/countries.model';

/**
 * Deloitte project details found in a Deloitte project
 */
export interface DeloitteProjectDetails {
  readonly billingCode: string;
  readonly industryOffering: DeloitteIndustryOffering;
  readonly clearance?: string;
  readonly itar?: boolean;
}

export interface DeloitteIndustryOffering {
  readonly projectType: DeloitteProjectTypeApi;
  readonly practice?: DeloittePracticeApi;
  readonly industry: DeloitteIndustryApi;
  readonly offering?: DeloitteOfferingApi;
}

export enum DeloitteLegalEntity {
  TOUCHE = 'Deloitte & Touche LLP',
  TAX = 'Deloitte Tax LLP',
  CONSULTING = `Deloitte Consulting LLP`,
  FINANCIAL_ADVISORY_SERVICES = `Deloitte Financial Advisory Services LLP`,
  TRANSACTIONS_AND_BUSINESS_ANALYTICS = `Deloitte Transactions and Business Analytics LLP`,
  INDIA_CONSULTING = `Deloitte Consulting India Private Limited`,
  INDIA_TAX = `Deloitte Tax India Pvt Ltd`,
  SPEC_PROJ = `Deloitte Spec Proj IPL`,
  INDIA_SVCS = `Deloitte SVCS India Pvt Ltd`,
  INDIA_AERS = `Deloitte AERS India Pvt Ltd`,
  INDIA_FAS = `Deloitte FAS India Pvt Ltd`,
  CONSULTING_OVERS = `Deloitte Consulting Overs`,
  LLP = `Deloitte LLP`,
  SERVICES = `Deloitte Services LP`,
  DC_OVERSEAS_SERVICES = `DC Overseas Services LLC`,
}

export enum DeloitteIndependenceRuleset {
  ADMIN = `Admin`,
  COVERED_PERSON = `Covered person`,
  MANAGER = 'Manager',
  PARTNER = `Partner`,
  STAFF = `Staff`,
}

export enum DeloitteCustomFieldRelationshipName {
  /** Used on Deloitte PJP to map WBS code to an FSS string */
  WBS_CODE_TO_FSS = `wbs_code-fss`,
  /** Unused, can replace the hardcoded mapping in DeloitteGigWorkerLevelMapping.php */
  GIG_WORKER_TO_LEVEL_GROUP = `gig-worker-level_group`,
  /** Used by FIS to map WBS code to the legal entity and location code */
  WBS_CODE_TO_COMPANY_CODE = `wbs_code-company_code`,
  /** Everything below is unused. Previously used for T278859 */
  WBS_CODE_TO_INDUSTRY_SECTOR = `wbs_code-industry_sector`,
  WBS_CODE_TO_INDUSTRY_GROUP = `wbs_code-industry_group`,
  WBS_CODE_TO_BUSINESS_LINE = `wbs_code-business_line`,
  WBS_CODE_TO_OFFERING_PORTFOLIO = `wbs_code-offering_portfolio`,
  // Unused, because market_offering is deprecated
  WBS_CODE_TO_MARKET_OFFERING = `wbs_code-market_offering`,
}

export const DELOITTE_PROJECT_TYPE_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteProjectTypeApi]: string;
} = {
  [DeloitteProjectTypeApi.CLIENT_BILLABLE]:
    'Client Billable (with Utilization)',
  [DeloitteProjectTypeApi.OTHER_INVESTMENT]:
    'Internal Investment (Adjusted Utilization)',
  [DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION]:
    'Marketplace or Firm Contribution (No Utilization)',
  [DeloitteProjectTypeApi.INTERNAL_SERVICES]:
    'Internal Services (Internal Practitioners Only - No Utilization)',
  // Deprecated - kept for older projects
  [DeloitteProjectTypeApi.FIRM_INITIATIVE]: 'Firm Initiative',
  // Deprecated - kept for older projects
  [DeloitteProjectTypeApi.PRD]: 'PRD',
  // Deprecated - kept for older projects
  [DeloitteProjectTypeApi.SKILLS_BASED_VOLUNTEERING]:
    'Skills-Based Volunteering',
};

/**
 * Maps the new project types with its utilization values.
 * Added 19/04/2022
 */
export const DELOITTE_PROJECT_TYPE_UTILIZATION_MAP: {
  readonly [k in DeloitteProjectTypeApi]?: DeloitteUtilizationApi;
} = {
  [DeloitteProjectTypeApi.CLIENT_BILLABLE]: DeloitteUtilizationApi.UTILIZATION,
  [DeloitteProjectTypeApi.INTERNAL_SERVICES]:
    DeloitteUtilizationApi.NO_UTILIZATION,
  [DeloitteProjectTypeApi.OTHER_INVESTMENT]:
    DeloitteUtilizationApi.ADJUSTED_UTILIZATION,
  [DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION]:
    DeloitteUtilizationApi.NO_UTILIZATION,
};

/**
 * Maps the deprecated project types to its new type
 */
export const LEGACY_DELOITTE_PROJECT_TYPE_MAP: {
  readonly [k in DeloitteProjectTypeApi]?: DeloitteProjectTypeApi;
} = {
  [DeloitteProjectTypeApi.FIRM_INITIATIVE]:
    DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION,
  [DeloitteProjectTypeApi.PRD]:
    DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION,
  [DeloitteProjectTypeApi.SKILLS_BASED_VOLUNTEERING]:
    DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION,
};

export const DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteContributionApi]: string;
} = {
  [DeloitteContributionApi.BUSINESS_DEVELOPMENT]: 'Business Development',
  [DeloitteContributionApi.DEI_AND_WELLBEING]: 'DEI and Well-being',
  [DeloitteContributionApi.LD_DESIGN_AND_DELIVERY]: 'L&D Design and Delivery',
  [DeloitteContributionApi.MARKET_SUPPORT]: 'Market Support',
  [DeloitteContributionApi.NETWORK_OR_LOCAL_OFFICE]: 'Network or Local Office',
  [DeloitteContributionApi.RECRUITING_AND_MENTORSHIP]:
    'Recruiting and Mentorship',
  [DeloitteContributionApi.SKILLS_BASED_VOLUNTEERING]:
    'Skills Based Volunteering',
};

export const DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteGigWorkerLevelApi]: string;
} = {
  [DeloitteGigWorkerLevelApi.ANALYST]: 'Analyst',
  [DeloitteGigWorkerLevelApi.CONSULTANT]: 'Consultant',
  [DeloitteGigWorkerLevelApi.SENIOR_CONSULTANT]: 'Senior Consultant',
  [DeloitteGigWorkerLevelApi.MANAGER]: 'Manager',
  [DeloitteGigWorkerLevelApi.SENIOR_MANAGER]: 'Senior Manager',
  [DeloitteGigWorkerLevelApi.PPMD]: 'PPMD',
};

export const DELOITTE_BUSINESS_LINE_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteBusinessLineApi]: string;
} = {
  [DeloitteBusinessLineApi.CONSULTING]: 'Consulting',
  [DeloitteBusinessLineApi.GPS_ENABLING_AREA]: 'GPS Enabling Area (EA)',
  // Deprecated - kept for older projects
  [DeloitteBusinessLineApi.AUDIT_AND_ASSURANCE]: 'Audit & Assurance',
  // Deprecated - kept for older projects
  [DeloitteBusinessLineApi.INTERNAL_SERVICES]: 'Internal Services',
  // Deprecated - kept for older projects
  [DeloitteBusinessLineApi.RISK_AND_FINANCIAL_ADVISORY]:
    'Risk & Financial Advisory',
  // Deprecated - kept for older projects
  [DeloitteBusinessLineApi.TAX]: 'Tax',
  [DeloitteBusinessLineApi.OTHER]: 'Other',
};

// practice is deprecated, kept for displaying old projects in project view page
export const DELOITTE_PRACTICE_DISPLAY_NAME_MAP: {
  readonly [k in DeloittePracticeApi]: string;
} = {
  [DeloittePracticeApi.COMMERCIAL_CORE]: 'Commercial Core',
  [DeloittePracticeApi.COMMERCIAL_USDC_ONSITE]: 'Commercial PDM',
  [DeloittePracticeApi.COMMERCIAL_USDC_INCENTER]: 'Commercial USDC',
  [DeloittePracticeApi.GPS_CORE]: 'GPS Core',
  [DeloittePracticeApi.GPS_USDC_ONSITE]: 'GPS PDM',
  [DeloittePracticeApi.GPS_USDC_INCENTER]: 'GPS USDC',
  [DeloittePracticeApi.USI_ONSITE]: 'Comm USI Onsite',
  [DeloittePracticeApi.USI_OFFSHORE]: 'Comm USI Offshore',
  [DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE]:
    'GPS Operational Excellence (OpX)',
  [DeloittePracticeApi.GPS_USI_OFFSHORE]: 'GPS USI Offshore',
  [DeloittePracticeApi.GPS_USI_ONSITE]: 'GPS USI Onsite',
  [DeloittePracticeApi.COMMERCIAL_ARDC]: 'Commercial ARDC',
};

export const DELOITTE_PRACTICE_GROUP_DISPLAY_NAME_MAP: {
  readonly [k in DeloittePracticeGroupApi]: string;
} = {
  [DeloittePracticeGroupApi.COMMERCIAL]: 'Commercial',
  [DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES]:
    'Government & Public Services',
  [DeloittePracticeGroupApi.OTHER]: 'Other',
};

export const DELOITTE_INDUSTRY_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteIndustryApi]: string;
} = {
  [DeloitteIndustryApi.AUTOMOTIVE]: 'Automotive',
  [DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS]:
    'Banking & Capital Markets',
  [DeloitteIndustryApi.CIVIL_GOVERNMENT]: 'Civil Government',
  [DeloitteIndustryApi.CROSS_SECTOR_CI]: 'Cross CI',
  [DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE]:
    'Defense, Security & Justice',
  [DeloitteIndustryApi.FEDERAL_HEALTH]: 'Federal Health',
  [DeloitteIndustryApi.HEALTH_CARE]: 'Health Care',
  [DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION]:
    'Industrial Products & Construction',
  [DeloitteIndustryApi.INSURANCE]: 'Insurance',
  [DeloitteIndustryApi.INVESTMENT_MANAGEMENT]: 'Investment Management',
  [DeloitteIndustryApi.LIFE_SCIENCES]: 'Life Sciences',
  [DeloitteIndustryApi.MEDIA_AND_ENTERTAINMENT]: 'Media & Entertainment',
  [DeloitteIndustryApi.REAL_ESTATE]: 'Real Estate',
  [DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS]:
    'Retail & Consumer Products',
  [DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION]:
    'State, Local, & Higher Education',
  [DeloitteIndustryApi.TECHNOLOGY]: 'Technology',
  [DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES]:
    'Transportation, Hospitality, & Services',
  [DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT]:
    'Telecom, Media & Entertainment',
  [DeloitteIndustryApi.NOT_APPLICABLE]: 'Not Applicable',
  [DeloitteIndustryApi.ENERGY_AND_CHEMICALS]: 'Energy & Chemicals',
  [DeloitteIndustryApi.HOLDING_COMPANIES]: 'Holding Companies',
  [DeloitteIndustryApi.INDIVIDUALS_AND_ESTATES]: 'Individuals and Estates',
  [DeloitteIndustryApi.INDUSTRY]: 'Industry',
  [DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES]:
    'Power, Utilities & Renewables',
  [DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES]: 'Unclassified Industries',
  [DeloitteIndustryApi.NOT_ASSIGNED]: 'Not Assigned',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.AUTOMOTIVE_TRANSPORTATION_HOSPITALITY_AND_SERVICES]:
    'Automotive, Transportation, Hospitality & Services',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.CROSS_SECTOR_C]: 'Cross Consumer',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.CROSS_SECTOR_ERI]: 'Cross ER&I',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.CROSS_SECTOR_FS]: 'Cross FS',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.CROSS_SECTOR_GPS]: 'Cross GPS',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.CROSS_SECTOR_LSHC]: 'Cross LS&HC',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.CROSS_SECTOR_TMT]: 'Cross TMT',
  /** @deprecated - kept for older projects */
  [DeloitteIndustryApi.CONSUMER_PRODUCTS]: 'Consumer Products',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.INVESTMENT_MANAGEMENT_AND_REAL_ESTATE]:
    'Investment Management & Real Estate',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.OIL_GAS_AND_CHEMICALS]: 'Oil, Gas & Chemicals',
  // Deprecated - kept for older projects
  [DeloitteIndustryApi.POWER_AND_UTILITIES]: 'Power & Utilities',
  /** @deprecated - kept for older projects */
  [DeloitteIndustryApi.RETAIL_WHOLESALE_AND_DISTRIBUTION]:
    'Retail, Wholesale, & Distribution',
};

// industry_group is deprecated, kept for displaying old projects in project view page
export const DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteIndustryGroupApi]: string;
} = {
  [DeloitteIndustryGroupApi.CONSUMER]: 'Consumer',
  [DeloitteIndustryGroupApi.ENERGY_RESOURCES_AND_INDUSTRIALS]:
    'Energy, Resources & Industrials',
  [DeloitteIndustryGroupApi.FINANCIAL_SERVICES]: 'Financial Services',
  [DeloitteIndustryGroupApi.GOVERNMENT_AND_PUBLIC_SECTOR]:
    'Government & Public Sector',
  [DeloitteIndustryGroupApi.LIFE_SCIENCES_AND_HEALTH_CARE]:
    'Life Sciences & Health Care',
  [DeloitteIndustryGroupApi.TECHNOLOGY_MEDIA_AND_TELECOM]:
    'Technology, Media & Telecom',
  [DeloitteIndustryGroupApi.INDUSTRY_NOT_APPLICABLE]: 'Industry Not Applicable',
};

// market_offering is deprecated, kept for displaying old projects in project view page
export const DELOITTE_OFFERING_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteOfferingApi]: string;
} = {
  [DeloitteOfferingApi.CBO_CROSS_CONSULTING_GROUP]: 'Cross Consulting Group',
  [DeloitteOfferingApi.CBO_OPERATIONS_TRANSFORMATION]:
    'Operations Transformation',
  [DeloitteOfferingApi.CBO_SYSTEMS_ENGINEERING]: 'Systems Engineering',
  [DeloitteOfferingApi.CBO_CLOUD_ENGINEERING]: 'Cloud Engineering',
  [DeloitteOfferingApi.CBO_CORE_INDUSTRY_SOLUTIONS]: 'Core Industry Solutions',
  [DeloitteOfferingApi.CBO_HEALTH_TECHNOLOGY]: 'Health Technology',
  [DeloitteOfferingApi.CBO_OTHER]: 'Other',
  [DeloitteOfferingApi.CNM_CUSTOMER_STRATEGY_AND_APPLIED_DESIGN]:
    'Customer Strategy & Applied Design',
  [DeloitteOfferingApi.CNM_ADVERTISING_MARKETING_AND_COMMERCE]:
    'Advertising, Marketing, & Commerce',
  [DeloitteOfferingApi.CNM_DIGITAL_CUSTOMER]: 'Digital Customer',
  [DeloitteOfferingApi.CNM_HUMAN_SERVICES_TRANSFORMATION]:
    'Human Services Transformation',
  [DeloitteOfferingApi.CNM_CROSS_CONSULTING_GROUP]: 'Cross Consulting Group',
  [DeloitteOfferingApi.CNM_ASSET_GROUP]: 'Asset Group',
  [DeloitteOfferingApi.CNM_OTHER]: 'Other',
  [DeloitteOfferingApi.EO_CROSS_CONSULTING_GROUP]: 'Cross Consulting Group',
  [DeloitteOfferingApi.EO_FINANCE_AND_ENTERPRISE_PERFORMANCE]:
    'Finance & Enterprise Performance',
  [DeloitteOfferingApi.EO_SUPPLY_CHAIN_AND_NETWORK_OPERATIONS]:
    'Supply Chain & Network Operations',
  [DeloitteOfferingApi.EO_TECHNOLOGY_SERVICES_OPTIMIZATION]:
    'Technology Services Optimization',
  [DeloitteOfferingApi.EO_SAP]: 'SAP',
  [DeloitteOfferingApi.EO_ORACLE]: 'Oracle',
  [DeloitteOfferingApi.EO_ERP_SAAS]: 'Emerging ERP Solutions',
  [DeloitteOfferingApi.EO_ASSET_GROUP]: 'Asset Group',
  [DeloitteOfferingApi.EO_OTHER]: 'Other',
  [DeloitteOfferingApi.HC_CROSS_CONSULTING_GROUP]: 'Cross Consulting Group',
  [DeloitteOfferingApi.HC_HR_TRANSFORMATION]: 'HR Transformation',
  [DeloitteOfferingApi.HC_ORGANIZATION_TRANSFORMATION]:
    'Organization Transformation',
  [DeloitteOfferingApi.HC_WORKFORCE_TRANSFORMATION]: 'Workforce Transformation',
  [DeloitteOfferingApi.HC_HC_AS_A_SERVICE]: 'HC as a Service',
  [DeloitteOfferingApi.HC_ASSET_GROUP]: 'Asset Group',
  [DeloitteOfferingApi.HC_OTHER]: 'Other',
  [DeloitteOfferingApi.MNA_MERGERS_AND_ACQUISITIONS]:
    'MAR Strategy & Diligence',
  [DeloitteOfferingApi.MNA_INTEGRATION_AND_DIVESTURE]:
    'MAR Integration & Divesture ',
  [DeloitteOfferingApi.MNA_OTHER]: 'Other',
  [DeloitteOfferingApi.MNA_CCG]: 'CCG',
  [DeloitteOfferingApi.SNA_CROSS_CONSULTING_GROUP]: 'Cross Consulting Group',
  [DeloitteOfferingApi.SNA_STRATEGY]: 'Strategy',
  [DeloitteOfferingApi.SNA_ANALYTICS_AND_COGNITIVE]: 'Analytics & Cognitive',
  [DeloitteOfferingApi.SNA_HYBRID_SOLUTIONS_AND_INCUBATION]:
    'Hybrid Solutions & Incubation',
  [DeloitteOfferingApi.SNA_ASSET_GROUP]: 'Asset Group',
  [DeloitteOfferingApi.SNA_OTHER]: 'Other',
  [DeloitteOfferingApi.NC_NATIONAL_CONSULTING]: 'National Consulting',
  [DeloitteOfferingApi.NC_ADJUSTMENTS_OR_OTHER]: 'Adjustments/Other',
  [DeloitteOfferingApi.NC_ALLIANCES]: 'Alliances',
  [DeloitteOfferingApi.NC_C_SUITE]: 'C Suite',
  [DeloitteOfferingApi.NC_CLIENT_AND_INDUSTRY]: 'Client & Industry',
  [DeloitteOfferingApi.NC_CLIENT_EXCELLENCE]: 'Client Excellence',
  [DeloitteOfferingApi.NC_CLOUD]: 'Cloud',
  [DeloitteOfferingApi.NC_CORPORATE_DEVELOPMENT]: 'Corporate Development',
  [DeloitteOfferingApi.NC_DELIVERY_EXCELLENCE]: 'Delivery Excellence',
  [DeloitteOfferingApi.NC_DTOC]: 'DTOC',
  [DeloitteOfferingApi.NC_INTERNATIONAL]: 'International',
  [DeloitteOfferingApi.NC_N_O_GLOBAL_CONSULTING_OFFICE]:
    'N.O Global Consulting Office',
  [DeloitteOfferingApi.NC_OFFERING_PORTFOLIO]: 'Offering Portfolio',
  [DeloitteOfferingApi.NC_OPERATION_AND_FINANCE]: 'Operation & Finance',
  [DeloitteOfferingApi.NC_OTHER_USI]: 'Other USI',
  [DeloitteOfferingApi.NC_PARTNER_MATTERS]: 'Partner Matters',
  [DeloitteOfferingApi.NC_SALES_AND_PURSUIT_EXCELLENCE]:
    'Sales & Pursuit Excellence',
  [DeloitteOfferingApi.NC_STRATEGY]: 'Strategy',
  [DeloitteOfferingApi.NC_TALENT]: 'Talent',
  [DeloitteOfferingApi.NC_TECH_AND_INNOVATION]: 'Tech & Innovation',
  [DeloitteOfferingApi.IS_GPS_BUSINESS_SYSTEMS]: 'GPS Business Systems',
  [DeloitteOfferingApi.IS_GPS_COMPLIANCE]: 'GPS Compliance',
  [DeloitteOfferingApi.IS_GPS_CONTRACTS]: 'GPS Contracts',
  [DeloitteOfferingApi.IS_GPS_FINANCE]: 'GPS Finance',
  [DeloitteOfferingApi.IS_GPS_SECURITY]: 'GPS Security',
  [DeloitteOfferingApi.P_ORGANIC]: 'Organic',
  [DeloitteOfferingApi.P_SNET]: 'SNET',
  [DeloitteOfferingApi.IS_GPS_BUSINESS_SERVICES]: 'GPS Business Services',
  [DeloitteOfferingApi.IS_GPS_OPS_MANAGEMENT]: 'GPS Ops Management',
  [DeloitteOfferingApi.IS_GPS_TALENT]: 'GPS Talent',
  [DeloitteOfferingApi.GPSEA_GPS_FINANCE]: 'GPS Finance',
  [DeloitteOfferingApi.GPSEA_GPS_CONTRACTS]: 'GPS Contracts',
  [DeloitteOfferingApi.GPSEA_GPS_CLIENT_EXCELLENCE]: 'GPS Client Excellence',
  [DeloitteOfferingApi.GPSEA_GPS_TALENT]: 'GPS Talent',
  [DeloitteOfferingApi.GPSEA_GPS_COMPLIANCE]: 'GPS Compliance',
  [DeloitteOfferingApi.GPSEA_GPS_OPERATIONS]: 'GPS Operations',
  [DeloitteOfferingApi.GPSEA_GPS_GENERAL_COUNSEL]: 'GPS General Counsel',
  [DeloitteOfferingApi.GPSEA_GPS_EA_MANAGEMENT]: 'GPS EA Management',
  [DeloitteOfferingApi.MARKETING_EXCELLENCE]: 'Marketing Excellence',
};

export const DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP: {
  readonly [k in DeloitteOfferingPortfolioApi]: string;
} = {
  [DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS]:
    'Core Business Operations',
  [DeloitteOfferingPortfolioApi.CUSTOMER]: 'Customer',
  [DeloitteOfferingPortfolioApi.CUSTOMER_AND_MARKETING]: 'Customer & Marketing',
  [DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS]:
    'Enterprise Performance',
  [DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS]:
    'M&A and Restructuring',
  [DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS]: 'Strategy & Analytics',
  [DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING]: 'National Consulting',
  [DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY]:
    'Innovation & Technology',
  [DeloitteOfferingPortfolioApi.INTERNAL_SERVICES]: 'Internal Services',
  [DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL]: 'DC Human Capital',
  [DeloitteOfferingPortfolioApi.DC_TECHNOLOGY]: 'DC Technology',
  [DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING_MANAGEMENT]:
    'National Consulting Management',
  [DeloitteOfferingPortfolioApi.GPS_CMG]: 'GPS CMG',
  [DeloitteOfferingPortfolioApi.GPS_COMPLIANCE]: 'GPS Compliance',
  [DeloitteOfferingPortfolioApi.GPS_CONTRACTS]: 'GPS Contracts',
  [DeloitteOfferingPortfolioApi.GPS_EA_MANAGEMENT]: 'GPS EA Management',
  [DeloitteOfferingPortfolioApi.GPS_FINANCE]: 'GPS Finance',
  [DeloitteOfferingPortfolioApi.GPS_GENERAL_COUNSEL]: 'GPS General Counsel',
  [DeloitteOfferingPortfolioApi.GPS_CONSULTING_NATL_OFFICE]:
    'GPS Consulting Natl Office',
  [DeloitteOfferingPortfolioApi.GPS_OPERATIONS]: 'GPS Operations',
  [DeloitteOfferingPortfolioApi.GPS_TALENT]: 'GPS Talent',
  // Deprecated - kept for older projects
  [DeloitteOfferingPortfolioApi.HUMAN_CAPITAL]: 'Human Capital',
  // Deprecated - kept for older projects
  [DeloitteOfferingPortfolioApi.PLATFORMS]: 'DC Platforms',
  // Deprecated - kept for older projects
  [DeloitteOfferingPortfolioApi.GPS_ENABLING_AREAS]: 'GPS Enabling Areas',
  // Deprecated - kept for older projects
  [DeloitteOfferingPortfolioApi.CLIENT_AND_MARKET_GROWTH]:
    'Client & Market Growth',
  // Deprecated - kept for older projects
  [DeloitteOfferingPortfolioApi.NBI]: 'NBI',
  // Deprecated - kept for older projects
  [DeloitteOfferingPortfolioApi.GPS_DC_MANAGEMENT]: 'GPS DC Management',
  [DeloitteOfferingPortfolioApi.NOT_APPLICABLE]: 'Not Applicable',
};

export const PROJECT_TYPE_OPTIONS: readonly SelectItem<
  '' | DeloitteProjectTypeApi
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_PROJECT_TYPE_DISPLAY_NAME_MAP[
        DeloitteProjectTypeApi.CLIENT_BILLABLE
      ],
    value: DeloitteProjectTypeApi.CLIENT_BILLABLE,
  },
  {
    displayText:
      DELOITTE_PROJECT_TYPE_DISPLAY_NAME_MAP[
        DeloitteProjectTypeApi.OTHER_INVESTMENT
      ],
    value: DeloitteProjectTypeApi.OTHER_INVESTMENT,
  },
  {
    displayText:
      DELOITTE_PROJECT_TYPE_DISPLAY_NAME_MAP[
        DeloitteProjectTypeApi.INTERNAL_SERVICES
      ],
    value: DeloitteProjectTypeApi.INTERNAL_SERVICES,
  },
  {
    displayText:
      DELOITTE_PROJECT_TYPE_DISPLAY_NAME_MAP[
        DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION
      ],
    value: DeloitteProjectTypeApi.MARKETPLACE_OR_FIRM_CONTRIBUTION,
  },
];

export const CONTRIBUTION_OPTIONS: readonly SelectItem<
  '' | DeloitteContributionApi
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.BUSINESS_DEVELOPMENT
      ],
    value: DeloitteContributionApi.BUSINESS_DEVELOPMENT,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.DEI_AND_WELLBEING
      ],
    value: DeloitteContributionApi.DEI_AND_WELLBEING,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.LD_DESIGN_AND_DELIVERY
      ],
    value: DeloitteContributionApi.LD_DESIGN_AND_DELIVERY,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.MARKET_SUPPORT
      ],
    value: DeloitteContributionApi.MARKET_SUPPORT,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.NETWORK_OR_LOCAL_OFFICE
      ],
    value: DeloitteContributionApi.NETWORK_OR_LOCAL_OFFICE,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.RECRUITING_AND_MENTORSHIP
      ],
    value: DeloitteContributionApi.RECRUITING_AND_MENTORSHIP,
  },
  {
    displayText:
      DELOITTE_CONTRIBUTION_DISPLAY_NAME_MAP[
        DeloitteContributionApi.SKILLS_BASED_VOLUNTEERING
      ],
    value: DeloitteContributionApi.SKILLS_BASED_VOLUNTEERING,
  },
];

export const BUSINESS_LINE_OPTIONS: readonly SelectItem<
  '' | DeloitteBusinessLineApi
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_BUSINESS_LINE_DISPLAY_NAME_MAP[
        DeloitteBusinessLineApi.CONSULTING
      ],
    value: DeloitteBusinessLineApi.CONSULTING,
  },
  {
    displayText:
      DELOITTE_BUSINESS_LINE_DISPLAY_NAME_MAP[
        DeloitteBusinessLineApi.GPS_ENABLING_AREA
      ],
    value: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
  },
  {
    displayText:
      DELOITTE_BUSINESS_LINE_DISPLAY_NAME_MAP[DeloitteBusinessLineApi.OTHER],
    value: DeloitteBusinessLineApi.OTHER,
  },
];

export const COUNTRY_ALIGNMENT_OPTIONS: readonly SelectItem<string>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: countries.IN.name,
    value: countries.IN.name,
  },
  {
    displayText: countries.US.name,
    value: countries.US.name,
  },
];

export const WORKER_LEVEL_OPTIONS: readonly SelectItem<
  '' | DeloitteGigWorkerLevelApi
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP[DeloitteGigWorkerLevelApi.ANALYST],
    value: DeloitteGigWorkerLevelApi.ANALYST,
  },
  {
    displayText:
      DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP[
        DeloitteGigWorkerLevelApi.CONSULTANT
      ],
    value: DeloitteGigWorkerLevelApi.CONSULTANT,
  },
  {
    displayText:
      DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP[
        DeloitteGigWorkerLevelApi.SENIOR_CONSULTANT
      ],
    value: DeloitteGigWorkerLevelApi.SENIOR_CONSULTANT,
  },
  {
    displayText:
      DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP[DeloitteGigWorkerLevelApi.MANAGER],
    value: DeloitteGigWorkerLevelApi.MANAGER,
  },
  {
    displayText:
      DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP[
        DeloitteGigWorkerLevelApi.SENIOR_MANAGER
      ],
    value: DeloitteGigWorkerLevelApi.SENIOR_MANAGER,
  },
  {
    displayText:
      DELOITTE_WORKER_LEVEL_DISPLAY_NAME_MAP[DeloitteGigWorkerLevelApi.PPMD],
    value: DeloitteGigWorkerLevelApi.PPMD,
  },
];

export const INDEPENDENCE_RULESET_OPTIONS: readonly SelectItem<
  '' | DeloitteIndependenceRuleset
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: DeloitteIndependenceRuleset.ADMIN,
    value: DeloitteIndependenceRuleset.ADMIN,
  },
  {
    displayText: DeloitteIndependenceRuleset.COVERED_PERSON,
    value: DeloitteIndependenceRuleset.COVERED_PERSON,
  },
  {
    displayText: DeloitteIndependenceRuleset.MANAGER,
    value: DeloitteIndependenceRuleset.MANAGER,
  },
  {
    displayText: DeloitteIndependenceRuleset.PARTNER,
    value: DeloitteIndependenceRuleset.PARTNER,
  },
  {
    displayText: DeloitteIndependenceRuleset.STAFF,
    value: DeloitteIndependenceRuleset.STAFF,
  },
];

export const LEGAL_ENTITY_OPTIONS: readonly SelectItem<
  '' | DeloitteLegalEntity
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: DeloitteLegalEntity.TOUCHE,
    value: DeloitteLegalEntity.TOUCHE,
  },
  {
    displayText: DeloitteLegalEntity.TAX,
    value: DeloitteLegalEntity.TAX,
  },
  {
    displayText: DeloitteLegalEntity.CONSULTING,
    value: DeloitteLegalEntity.CONSULTING,
  },
  {
    displayText: DeloitteLegalEntity.FINANCIAL_ADVISORY_SERVICES,
    value: DeloitteLegalEntity.FINANCIAL_ADVISORY_SERVICES,
  },
  {
    displayText: DeloitteLegalEntity.TRANSACTIONS_AND_BUSINESS_ANALYTICS,
    value: DeloitteLegalEntity.TRANSACTIONS_AND_BUSINESS_ANALYTICS,
  },
  {
    displayText: DeloitteLegalEntity.INDIA_CONSULTING,
    value: DeloitteLegalEntity.INDIA_CONSULTING,
  },
  {
    displayText: DeloitteLegalEntity.INDIA_TAX,
    value: DeloitteLegalEntity.INDIA_TAX,
  },
  {
    displayText: DeloitteLegalEntity.SPEC_PROJ,
    value: DeloitteLegalEntity.SPEC_PROJ,
  },
  {
    displayText: DeloitteLegalEntity.INDIA_SVCS,
    value: DeloitteLegalEntity.INDIA_SVCS,
  },
  {
    displayText: DeloitteLegalEntity.INDIA_FAS,
    value: DeloitteLegalEntity.INDIA_FAS,
  },
  {
    displayText: DeloitteLegalEntity.CONSULTING_OVERS,
    value: DeloitteLegalEntity.CONSULTING_OVERS,
  },
  {
    displayText: DeloitteLegalEntity.LLP,
    value: DeloitteLegalEntity.LLP,
  },
  {
    displayText: DeloitteLegalEntity.SERVICES,
    value: DeloitteLegalEntity.SERVICES,
  },
  {
    displayText: DeloitteLegalEntity.DC_OVERSEAS_SERVICES,
    value: DeloitteLegalEntity.DC_OVERSEAS_SERVICES,
  },
];

export const MEMBER_FIRM_ALIGNMENT_OPTIONS: readonly SelectItem<string>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: countries.US.name,
    value: countries.US.code,
  },
];

// Project custom field values are snake_case in the DB.
// See DeloitteCustomFieldsMapping.php and gaf/static/modules/search/constants/deloitte.js
/** @deprecated See T283869 */
export const PRACTICE_OPTIONS: readonly SelectItem<'' | DeloittePracticeApi>[] =
  [
    {
      displayText: '',
      value: '',
      disabled: true,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_CORE],
      value: DeloittePracticeApi.COMMERCIAL_CORE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
          DeloittePracticeApi.COMMERCIAL_USDC_ONSITE
        ],
      value: DeloittePracticeApi.COMMERCIAL_USDC_ONSITE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
          DeloittePracticeApi.COMMERCIAL_USDC_INCENTER
        ],
      value: DeloittePracticeApi.COMMERCIAL_USDC_INCENTER,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_CORE],
      value: DeloittePracticeApi.GPS_CORE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
          DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE
        ],
      value: DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_ONSITE],
      value: DeloittePracticeApi.GPS_USDC_ONSITE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
          DeloittePracticeApi.GPS_USDC_INCENTER
        ],
      value: DeloittePracticeApi.GPS_USDC_INCENTER,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_OFFSHORE],
      value: DeloittePracticeApi.USI_OFFSHORE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_ONSITE],
      value: DeloittePracticeApi.USI_ONSITE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
          DeloittePracticeApi.GPS_USI_OFFSHORE
        ],
      value: DeloittePracticeApi.GPS_USI_OFFSHORE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_ONSITE],
      value: DeloittePracticeApi.GPS_USI_ONSITE,
    },
    {
      displayText:
        DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_ARDC],
      value: DeloittePracticeApi.COMMERCIAL_ARDC,
    },
  ];

// User custom field values are Upper Case in the DB.
// See DeloitteCustomFieldsMapping.php and gaf/static/modules/search/constants/deloitte.js
export const USER_PRACTICE_OPTIONS: readonly SelectItem<string>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_ARDC],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_ARDC],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_CORE],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_CORE],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.COMMERCIAL_USDC_ONSITE
      ],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.COMMERCIAL_USDC_ONSITE
      ],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.COMMERCIAL_USDC_INCENTER
      ],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.COMMERCIAL_USDC_INCENTER
      ],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_OFFSHORE],
    value: DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_OFFSHORE],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_ONSITE],
    // FIXME: T267027
    value: 'Comm USI On Site',
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_CORE],
    value: DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_CORE],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE
      ],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE
      ],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_ONSITE],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_ONSITE],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_INCENTER],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_INCENTER],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_OFFSHORE],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_OFFSHORE],
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_ONSITE],
    value:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_ONSITE],
  },
];

/** @deprecated See T283869 */
export const LIMIT_PRACTICE_OPTIONS: readonly SelectItem<
  '' | DeloittePracticeApi
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_CORE],
    value: DeloittePracticeApi.COMMERCIAL_CORE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.COMMERCIAL_USDC_ONSITE
      ],
    value: DeloittePracticeApi.COMMERCIAL_USDC_ONSITE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.COMMERCIAL_USDC_INCENTER
      ],
    value: DeloittePracticeApi.COMMERCIAL_USDC_INCENTER,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_CORE],
    value: DeloittePracticeApi.GPS_CORE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[
        DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE
      ],
    value: DeloittePracticeApi.GPS_OPERATIONAL_EXCELLENCE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_ONSITE],
    value: DeloittePracticeApi.GPS_USDC_ONSITE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USDC_INCENTER],
    value: DeloittePracticeApi.GPS_USDC_INCENTER,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_OFFSHORE],
    value: DeloittePracticeApi.USI_OFFSHORE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.USI_ONSITE],
    value: DeloittePracticeApi.USI_ONSITE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_OFFSHORE],
    value: DeloittePracticeApi.GPS_USI_OFFSHORE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.GPS_USI_ONSITE],
    value: DeloittePracticeApi.GPS_USI_ONSITE,
  },
  {
    displayText:
      DELOITTE_PRACTICE_DISPLAY_NAME_MAP[DeloittePracticeApi.COMMERCIAL_ARDC],
    value: DeloittePracticeApi.COMMERCIAL_ARDC,
  },
];

/** @deprecated See T283869 */
export const INDUSTRY_GROUP_OPTIONS: readonly (
  | SelectGroups<DeloitteIndustryApi>
  | SelectItem<string>
)[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.CONSUMER
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.CROSS_SECTOR_C
          ],
        value: DeloitteIndustryApi.CROSS_SECTOR_C,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi
              .AUTOMOTIVE_TRANSPORTATION_HOSPITALITY_AND_SERVICES
          ],
        value:
          DeloitteIndustryApi.AUTOMOTIVE_TRANSPORTATION_HOSPITALITY_AND_SERVICES,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS
          ],
        value: DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
      },
    ],
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.TECHNOLOGY_MEDIA_AND_TELECOM
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.CROSS_SECTOR_TMT
          ],
        value: DeloitteIndustryApi.CROSS_SECTOR_TMT,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[DeloitteIndustryApi.TECHNOLOGY],
        value: DeloitteIndustryApi.TECHNOLOGY,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT
          ],
        value: DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
      },
    ],
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.ENERGY_RESOURCES_AND_INDUSTRIALS
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.CROSS_SECTOR_ERI
          ],
        value: DeloitteIndustryApi.CROSS_SECTOR_ERI,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION
          ],
        value: DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.OIL_GAS_AND_CHEMICALS
          ],
        value: DeloitteIndustryApi.OIL_GAS_AND_CHEMICALS,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.POWER_AND_UTILITIES
          ],
        value: DeloitteIndustryApi.POWER_AND_UTILITIES,
      },
    ],
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.FINANCIAL_SERVICES
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.CROSS_SECTOR_FS
          ],
        value: DeloitteIndustryApi.CROSS_SECTOR_FS,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS
          ],
        value: DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[DeloitteIndustryApi.INSURANCE],
        value: DeloitteIndustryApi.INSURANCE,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.INVESTMENT_MANAGEMENT_AND_REAL_ESTATE
          ],
        value: DeloitteIndustryApi.INVESTMENT_MANAGEMENT_AND_REAL_ESTATE,
      },
    ],
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.GOVERNMENT_AND_PUBLIC_SECTOR
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.CROSS_SECTOR_GPS
          ],
        value: DeloitteIndustryApi.CROSS_SECTOR_GPS,
      },
    ],
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.LIFE_SCIENCES_AND_HEALTH_CARE
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.CROSS_SECTOR_LSHC
          ],
        value: DeloitteIndustryApi.CROSS_SECTOR_LSHC,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[DeloitteIndustryApi.HEALTH_CARE],
        value: DeloitteIndustryApi.HEALTH_CARE,
      },
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[DeloitteIndustryApi.LIFE_SCIENCES],
        value: DeloitteIndustryApi.LIFE_SCIENCES,
      },
    ],
  },
  {
    groupName:
      DELOITTE_INDUSTRY_GROUP_DISPLAY_NAME_MAP[
        DeloitteIndustryGroupApi.INDUSTRY_NOT_APPLICABLE
      ],
    options: [
      {
        displayText:
          DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[
            DeloitteIndustryApi.NOT_APPLICABLE
          ],
        value: DeloitteIndustryApi.NOT_APPLICABLE,
      },
    ],
  },
];

/** @deprecated See T283869 */
export const OFFERING_OPTIONS: readonly (
  | SelectGroups<DeloitteOfferingApi>
  | SelectItem<string>
)[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CBO_CLOUD_ENGINEERING
          ],
        value: DeloitteOfferingApi.CBO_CLOUD_ENGINEERING,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CBO_CORE_INDUSTRY_SOLUTIONS
          ],
        value: DeloitteOfferingApi.CBO_CORE_INDUSTRY_SOLUTIONS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CBO_CROSS_CONSULTING_GROUP
          ],
        value: DeloitteOfferingApi.CBO_CROSS_CONSULTING_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CBO_HEALTH_TECHNOLOGY
          ],
        value: DeloitteOfferingApi.CBO_HEALTH_TECHNOLOGY,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CBO_OPERATIONS_TRANSFORMATION
          ],
        value: DeloitteOfferingApi.CBO_OPERATIONS_TRANSFORMATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CBO_SYSTEMS_ENGINEERING
          ],
        value: DeloitteOfferingApi.CBO_SYSTEMS_ENGINEERING,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.CBO_OTHER],
        value: DeloitteOfferingApi.CBO_OTHER,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CUSTOMER_AND_MARKETING
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CNM_ASSET_GROUP
          ],
        value: DeloitteOfferingApi.CNM_ASSET_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CNM_ADVERTISING_MARKETING_AND_COMMERCE
          ],
        value: DeloitteOfferingApi.CNM_ADVERTISING_MARKETING_AND_COMMERCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CNM_CROSS_CONSULTING_GROUP
          ],
        value: DeloitteOfferingApi.CNM_CROSS_CONSULTING_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CNM_CUSTOMER_STRATEGY_AND_APPLIED_DESIGN
          ],
        value: DeloitteOfferingApi.CNM_CUSTOMER_STRATEGY_AND_APPLIED_DESIGN,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CNM_DIGITAL_CUSTOMER
          ],
        value: DeloitteOfferingApi.CNM_DIGITAL_CUSTOMER,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.CNM_HUMAN_SERVICES_TRANSFORMATION
          ],
        value: DeloitteOfferingApi.CNM_HUMAN_SERVICES_TRANSFORMATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.CNM_OTHER],
        value: DeloitteOfferingApi.CNM_OTHER,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.EO_ASSET_GROUP
          ],
        value: DeloitteOfferingApi.EO_ASSET_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.EO_CROSS_CONSULTING_GROUP
          ],
        value: DeloitteOfferingApi.EO_CROSS_CONSULTING_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.EO_ERP_SAAS],
        value: DeloitteOfferingApi.EO_ERP_SAAS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.EO_FINANCE_AND_ENTERPRISE_PERFORMANCE
          ],
        value: DeloitteOfferingApi.EO_FINANCE_AND_ENTERPRISE_PERFORMANCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.EO_ORACLE],
        value: DeloitteOfferingApi.EO_ORACLE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.EO_SAP],
        value: DeloitteOfferingApi.EO_SAP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.EO_SUPPLY_CHAIN_AND_NETWORK_OPERATIONS
          ],
        value: DeloitteOfferingApi.EO_SUPPLY_CHAIN_AND_NETWORK_OPERATIONS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.EO_TECHNOLOGY_SERVICES_OPTIMIZATION
          ],
        value: DeloitteOfferingApi.EO_TECHNOLOGY_SERVICES_OPTIMIZATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.EO_OTHER],
        value: DeloitteOfferingApi.EO_OTHER,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_DC_MANAGEMENT
      ],
    options: [],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_ENABLING_AREAS
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_FINANCE
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_FINANCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_CONTRACTS
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_CONTRACTS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_CLIENT_EXCELLENCE
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_CLIENT_EXCELLENCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_TALENT
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_TALENT,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_COMPLIANCE
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_COMPLIANCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_OPERATIONS
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_OPERATIONS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_GENERAL_COUNSEL
          ],
        value: DeloitteOfferingApi.GPSEA_GPS_GENERAL_COUNSEL,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.GPSEA_GPS_EA_MANAGEMENT
          ],
        value: DeloitteOfferingApi.EO_TECHNOLOGY_SERVICES_OPTIMIZATION,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.HUMAN_CAPITAL
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.HC_ASSET_GROUP
          ],
        value: DeloitteOfferingApi.HC_ASSET_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.HC_CROSS_CONSULTING_GROUP
          ],
        value: DeloitteOfferingApi.HC_CROSS_CONSULTING_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.HC_HC_AS_A_SERVICE
          ],
        value: DeloitteOfferingApi.HC_HC_AS_A_SERVICE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.HC_HR_TRANSFORMATION
          ],
        value: DeloitteOfferingApi.HC_HR_TRANSFORMATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.HC_ORGANIZATION_TRANSFORMATION
          ],
        value: DeloitteOfferingApi.HC_ORGANIZATION_TRANSFORMATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.HC_WORKFORCE_TRANSFORMATION
          ],
        value: DeloitteOfferingApi.HC_WORKFORCE_TRANSFORMATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.HC_OTHER],
        value: DeloitteOfferingApi.HC_OTHER,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.MNA_CCG],
        value: DeloitteOfferingApi.MNA_CCG,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.MNA_MERGERS_AND_ACQUISITIONS
          ],
        value: DeloitteOfferingApi.MNA_MERGERS_AND_ACQUISITIONS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.MNA_INTEGRATION_AND_DIVESTURE
          ],
        value: DeloitteOfferingApi.MNA_INTEGRATION_AND_DIVESTURE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.MNA_OTHER],
        value: DeloitteOfferingApi.MNA_OTHER,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_ADJUSTMENTS_OR_OTHER
          ],
        value: DeloitteOfferingApi.NC_ADJUSTMENTS_OR_OTHER,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_ALLIANCES],
        value: DeloitteOfferingApi.NC_ALLIANCES,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_C_SUITE],
        value: DeloitteOfferingApi.NC_C_SUITE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_CLIENT_AND_INDUSTRY
          ],
        value: DeloitteOfferingApi.NC_CLIENT_AND_INDUSTRY,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_CLIENT_EXCELLENCE
          ],
        value: DeloitteOfferingApi.NC_CLIENT_EXCELLENCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_CLOUD],
        value: DeloitteOfferingApi.NC_CLOUD,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_CORPORATE_DEVELOPMENT
          ],
        value: DeloitteOfferingApi.NC_CORPORATE_DEVELOPMENT,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_DELIVERY_EXCELLENCE
          ],
        value: DeloitteOfferingApi.NC_DELIVERY_EXCELLENCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_DTOC],
        value: DeloitteOfferingApi.NC_DTOC,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_INTERNATIONAL
          ],
        value: DeloitteOfferingApi.NC_INTERNATIONAL,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_N_O_GLOBAL_CONSULTING_OFFICE
          ],
        value: DeloitteOfferingApi.NC_N_O_GLOBAL_CONSULTING_OFFICE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_NATIONAL_CONSULTING
          ],
        value: DeloitteOfferingApi.NC_NATIONAL_CONSULTING,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_OFFERING_PORTFOLIO
          ],
        value: DeloitteOfferingApi.NC_OFFERING_PORTFOLIO,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_OPERATION_AND_FINANCE
          ],
        value: DeloitteOfferingApi.NC_OPERATION_AND_FINANCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_OTHER_USI],
        value: DeloitteOfferingApi.NC_OTHER_USI,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_PARTNER_MATTERS
          ],
        value: DeloitteOfferingApi.NC_PARTNER_MATTERS,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_SALES_AND_PURSUIT_EXCELLENCE
          ],
        value: DeloitteOfferingApi.NC_SALES_AND_PURSUIT_EXCELLENCE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_STRATEGY],
        value: DeloitteOfferingApi.NC_STRATEGY,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.NC_TALENT],
        value: DeloitteOfferingApi.NC_TALENT,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.NC_TECH_AND_INNOVATION
          ],
        value: DeloitteOfferingApi.NC_TECH_AND_INNOVATION,
      },
    ],
  },
  {
    // NBI is deprecated but adding here so it is selectable in analytics dashboard
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.NBI
      ],
    options: [],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.PLATFORMS
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.P_ORGANIC],
        value: DeloitteOfferingApi.P_ORGANIC,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.P_SNET],
        value: DeloitteOfferingApi.P_SNET,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.SNA_ANALYTICS_AND_COGNITIVE
          ],
        value: DeloitteOfferingApi.SNA_ANALYTICS_AND_COGNITIVE,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.SNA_ASSET_GROUP
          ],
        value: DeloitteOfferingApi.SNA_ASSET_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.SNA_CROSS_CONSULTING_GROUP
          ],
        value: DeloitteOfferingApi.SNA_CROSS_CONSULTING_GROUP,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.SNA_HYBRID_SOLUTIONS_AND_INCUBATION
          ],
        value: DeloitteOfferingApi.SNA_HYBRID_SOLUTIONS_AND_INCUBATION,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.SNA_STRATEGY],
        value: DeloitteOfferingApi.SNA_STRATEGY,
      },
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[DeloitteOfferingApi.SNA_OTHER],
        value: DeloitteOfferingApi.SNA_OTHER,
      },
    ],
  },
  {
    groupName:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CLIENT_AND_MARKET_GROWTH
      ],
    options: [
      {
        displayText:
          DELOITTE_OFFERING_DISPLAY_NAME_MAP[
            DeloitteOfferingApi.MARKETING_EXCELLENCE
          ],
        value: DeloitteOfferingApi.MARKETING_EXCELLENCE,
      },
    ],
  },
];

// Project custom field values are snake_case in the DB.
// See DeloitteCustomFieldsMapping.php and gaf/static/modules/search/constants/deloitte.js
/** @deprecated See T283869. Replaced by OFFERING_PORTFOLIO_OPTIONS_WITH_DEPENDENCIES */
export const OFFERING_PORTFOLIO_OPTIONS: readonly SelectItem<
  '' | DeloitteOfferingPortfolioApi
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS
      ],
    value: DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CUSTOMER_AND_MARKETING
      ],
    value: DeloitteOfferingPortfolioApi.CUSTOMER_AND_MARKETING,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.HUMAN_CAPITAL
      ],
    value: DeloitteOfferingPortfolioApi.HUMAN_CAPITAL,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.PLATFORMS
      ],
    value: DeloitteOfferingPortfolioApi.PLATFORMS,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_ENABLING_AREAS
      ],
    value: DeloitteOfferingPortfolioApi.GPS_ENABLING_AREAS,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS
      ],
    value: DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS
      ],
    value: DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING
      ],
    value: DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS
      ],
    value: DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CLIENT_AND_MARKET_GROWTH
      ],
    value: DeloitteOfferingPortfolioApi.CLIENT_AND_MARKET_GROWTH,
  },
];

// User custom field values are Upper Case in the DB.
// See DeloitteCustomFieldsMapping.php and gaf/static/modules/search/constants/deloitte.js
export const USER_OFFERING_PORTFOLIO_OPTIONS: readonly SelectItem<string>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS
      ],
  },
  {
    // T302441 renamed to value to `Customer & Marketing` to `Customer`
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CUSTOMER
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.CUSTOMER
      ],
  },
  {
    // T283869 renamed from `Human Capital` to `DC Human Capital`
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_CMG
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_CMG
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_CONSULTING_NATL_OFFICE
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_CONSULTING_NATL_OFFICE
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_CONTRACTS
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_CONTRACTS
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_COMPLIANCE
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_COMPLIANCE
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_FINANCE
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_FINANCE
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_OPERATIONS
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_OPERATIONS
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_TALENT
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.GPS_TALENT
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING
      ],
  },
  {
    displayText:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS
      ],
    value:
      DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[
        DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS
      ],
  },
];

// See DeloitteUserCustomFieldName
export enum DeloitteUserSearchField {
  COUNTRY_ALIGNMENT = 'country_alignment',
  CERTIFICATIONS = 'certifications',
  GIG_WORKER_LEVEL = 'level',
  INDEPENDENCE_RULESET = 'independence_ruleset',
  LEGAL_ENTITY = 'legal_entity_alignment',
  LOCATION = 'location',
  MEMBER_FIRM_ALIGNMENT = 'member_firm_alignment',
  OFFERING_PORTFOLIO = 'offering_portfolio',
  PRACTICE = 'practice',
}

export enum DeloitteProjectPostField {
  IS_ON_BEHALF = 'is_on_behalf',
  OWNERSHIP_USER = 'ownershipUser',
  TITLE = 'title',
  DESCRIPTION = 'description',
  SKILLS = 'skills',
  AUDIENCE = 'audience',
  BUDGET_TYPE = 'budget_type',
  BUDGET = 'budget',
  START_DATE = 'start_date',
  COMPLETION_DATE = 'completion_date',
  IS_COMPLETION_DATE_ONGOING = 'is_completion_date_ongoing',
  BILLABLE_HOURS = 'billable_hours',
  LOCATION = 'location',
  AGREE_TO_TERMS_AND_CONDITIONS = 'agree_to_terms_and_conditions',
  IS_LOCAL_PROJECT = 'is_local_project',
  /** @deprecated as of T283869 */
  IS_CLEARANCE_REQUIRED = 'is_clearance_required',
  ARE_CERTIFICATIONS_LIMITED = 'are_certifications_limited',
  ARE_PRACTICES_LIMITED = 'are_practices_limited',
  ARE_OFFERING_PORTFOLIOS_LIMITED = 'are_offering_portfolios_limited',
  // Project custom fields, maps 1-to-1 to custom field name in DB
  BILLING_CODE = 'wbs_code',
  NO_BILLING_CODE = 'no_wbs_code', // not a custom field
  PROJECT_TYPE = 'project_type',
  /** Deprecated - merged with 'project_type' custom field */
  UTILIZATION = 'utilization',
  CONTRIBUTION = 'contribution',
  BUSINESS_LINE = 'business_line',
  /** @deprecated as of T283869 */
  PRACTICE = 'practice',
  PRACTICE_GROUP = 'practice_group',
  INDUSTRY_SECTOR = 'industry_sector',
  /** @deprecated as of T283869 */
  INDUSTRY_GROUP = 'industry_group',
  /** @deprecated as of T283869 */
  MARKET_OFFERING = 'market_offering',
  OFFERING_PORTFOLIO = 'offering_portfolio',
  /** @deprecated as of T283869 */
  CLEARANCE = 'clearance',
  IS_SUBJECT_TO_ITAR = 'itar',
  /** @deprecated as of T283869 */
  LIMIT_CERTIFICATIONS = 'limit_certifications',
  /** @deprecated as of T283869 */
  LIMIT_PRACTICES = 'limit_practices',
  /** @deprecated as of T283869 */
  LIMIT_OFFERING_PORTFOLIOS = 'limit_offering_portfolio',
  LIMIT_GIG_WORKER_LEVEL = 'limit_gig_worker_level',
  IS_GROUP_LIMITED = 'is_group_limited',
  LIMIT_GROUP_MEMBERS = 'limit_group_members',
}

export enum DeloitteUserCustomField {
  FIRST_NAME = 'firstname',
  LAST_NAME = 'lastname',
}

/** Practice group options (dependent on business) */
export const PRACTICE_GROUP_OPTIONS_WITH_DEPENDENCIES: readonly {
  readonly business: DeloitteBusinessLineApi;
  readonly options: readonly SelectItem<'' | DeloittePracticeGroupApi>[];
}[] = [
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      {
        displayText:
          DELOITTE_PRACTICE_GROUP_DISPLAY_NAME_MAP[
            DeloittePracticeGroupApi.COMMERCIAL
          ],
        value: DeloittePracticeGroupApi.COMMERCIAL,
      },
      {
        displayText:
          DELOITTE_PRACTICE_GROUP_DISPLAY_NAME_MAP[
            DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES
          ],
        value: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
      },
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      {
        displayText:
          DELOITTE_PRACTICE_GROUP_DISPLAY_NAME_MAP[
            DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES
          ],
        value: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
      },
    ],
  },
  {
    business: DeloitteBusinessLineApi.OTHER,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      {
        displayText:
          DELOITTE_PRACTICE_GROUP_DISPLAY_NAME_MAP[
            DeloittePracticeGroupApi.OTHER
          ],
        value: DeloittePracticeGroupApi.OTHER,
      },
    ],
  },
];

/** Offering Portfolio options (dependent on business and practice) */
export const OFFERING_PORTFOLIO_OPTIONS_WITH_DEPENDENCIES: readonly {
  readonly business: DeloitteBusinessLineApi;
  readonly practiceGroup: DeloittePracticeGroupApi;
  readonly options: readonly SelectItem<'' | DeloitteOfferingPortfolioApi>[];
}[] = [
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS,
        DeloitteOfferingPortfolioApi.CUSTOMER,
        DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL,
        DeloitteOfferingPortfolioApi.DC_TECHNOLOGY,
        DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS,
        DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY,
        DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS,
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING,
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING_MANAGEMENT,
        DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS,
      ].map(industry => ({
        displayText: DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS,
        DeloitteOfferingPortfolioApi.CUSTOMER,
        DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL,
        DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS,
        DeloitteOfferingPortfolioApi.GPS_CONSULTING_NATL_OFFICE,
        DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY,
        DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS,
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING,
        DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING_MANAGEMENT,
        DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS,
      ].map(industry => ({
        displayText: DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteOfferingPortfolioApi.GPS_CMG,
        DeloitteOfferingPortfolioApi.GPS_COMPLIANCE,
        DeloitteOfferingPortfolioApi.GPS_CONTRACTS,
        DeloitteOfferingPortfolioApi.GPS_EA_MANAGEMENT,
        DeloitteOfferingPortfolioApi.GPS_FINANCE,
        DeloitteOfferingPortfolioApi.GPS_GENERAL_COUNSEL,
        DeloitteOfferingPortfolioApi.GPS_OPERATIONS,
        DeloitteOfferingPortfolioApi.GPS_TALENT,
      ].map(industry => ({
        displayText: DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.OTHER,
    practiceGroup: DeloittePracticeGroupApi.OTHER,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteOfferingPortfolioApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_OFFERING_PORTFOLIO_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
];

/** Industry options (dependent on Business, Practice Group and Offering Portfolio) */
export const INDUSTRY_OPTIONS_WITH_DEPENDENCIES: readonly {
  readonly business: DeloitteBusinessLineApi;
  readonly practiceGroup: DeloittePracticeGroupApi;
  readonly offeringPortfolio: DeloitteOfferingPortfolioApi;
  readonly options: readonly SelectItem<'' | DeloitteIndustryApi>[];
}[] = [
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.CUSTOMER,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.HOLDING_COMPANIES,
        DeloitteIndustryApi.INDIVIDUALS_AND_ESTATES,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.HOLDING_COMPANIES,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.DC_TECHNOLOGY,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.HOLDING_COMPANIES,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.INDIVIDUALS_AND_ESTATES,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.HOLDING_COMPANIES,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio:
      DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING_MANAGEMENT,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.HOLDING_COMPANIES,
        DeloitteIndustryApi.INDIVIDUALS_AND_ESTATES,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.COMMERCIAL,
    offeringPortfolio: DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.AUTOMOTIVE,
        DeloitteIndustryApi.BANKING_AND_CAPITAL_MARKETS,
        DeloitteIndustryApi.ENERGY_AND_CHEMICALS,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.INDUSTRIAL_PRODUCTS_AND_CONSTRUCTION,
        DeloitteIndustryApi.INSURANCE,
        DeloitteIndustryApi.INVESTMENT_MANAGEMENT,
        DeloitteIndustryApi.LIFE_SCIENCES,
        DeloitteIndustryApi.NOT_ASSIGNED,
        DeloitteIndustryApi.POWER_UTILITIES_AND_RENEWABLES,
        DeloitteIndustryApi.REAL_ESTATE,
        DeloitteIndustryApi.RETAIL_AND_CONSUMER_PRODUCTS,
        DeloitteIndustryApi.TECHNOLOGY,
        DeloitteIndustryApi.TELECOM_MEDIA_AND_ENTERTAINMENT,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.CUSTOMER,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.DC_HUMAN_CAPITAL,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.ENTERPRISE_OPERATIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_CONSULTING_NATL_OFFICE,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.HEALTH_CARE,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.TRANSPORTATION_HOSPITALITY_AND_SERVICES,
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.INNOVATION_AND_TECHNOLOGY,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.MERGERS_AND_ACQUISITIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio:
      DeloitteOfferingPortfolioApi.NATIONAL_CONSULTING_MANAGEMENT,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.CONSULTING,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.STRATEGY_AND_ANALYTICS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.FEDERAL_HEALTH,
        DeloitteIndustryApi.STATE_LOCAL_AND_HIGHER_EDUCATION,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_CMG,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.CIVIL_GOVERNMENT,
        DeloitteIndustryApi.DEFENSE_SECURITY_AND_JUSTICE,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_COMPLIANCE,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_CONTRACTS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_EA_MANAGEMENT,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_FINANCE,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[
        DeloitteIndustryApi.UNCLASSIFIED_INDUSTRIES,
        DeloitteIndustryApi.NOT_APPLICABLE,
      ].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_GENERAL_COUNSEL,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_OPERATIONS,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.GPS_ENABLING_AREA,
    practiceGroup: DeloittePracticeGroupApi.GOVERNMENT_AND_PUBLIC_SERVICES,
    offeringPortfolio: DeloitteOfferingPortfolioApi.GPS_TALENT,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
  {
    business: DeloitteBusinessLineApi.OTHER,
    practiceGroup: DeloittePracticeGroupApi.OTHER,
    offeringPortfolio: DeloitteOfferingPortfolioApi.NOT_APPLICABLE,
    options: [
      {
        displayText: '',
        value: '',
        disabled: true,
      },
      ...[DeloitteIndustryApi.NOT_APPLICABLE].map(industry => ({
        displayText: DELOITTE_INDUSTRY_DISPLAY_NAME_MAP[industry],
        value: industry,
      })),
    ],
  },
];

export const MYGIGS_ENABLING_AREA_GROUP_SEO_URL = 'mygigs-enabling-area-users';
